import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { TranslocoRootModule } from 'app/transloco-root.module';
import { AIRoleplayFormComponent } from './components/ai-roleplay-form/ai-roleplay-form.component';
import { AIRoleplayPageComponent } from './page/ai-roleplay-page/ai-roleplay-page.component';
import { AIRoleplayRoutingModule } from 'app/routing/ai-roleplay-routing.module';
import { AiRoleplayConversationPageComponent } from './page/ai-roleplay-conversation-page/ai-roleplay-conversation-page.component';
import { AiRoleplayPageAdminComponent } from './page-admin/ai-roleplay-page-admin/ai-roleplay-page-admin.component';
import { TableModule } from 'primeng/table';
import { AiRoleplayEditComponent } from './page-admin/ai-roleplay-edit/ai-roleplay-edit.component';

@NgModule({
  declarations: [
    AIRoleplayFormComponent,
    AIRoleplayPageComponent,
    AiRoleplayConversationPageComponent,
    AiRoleplayConversationPageComponent,
    AiRoleplayPageAdminComponent,
    AiRoleplayEditComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    CKEditorModule,
    TranslocoRootModule,
    AIRoleplayRoutingModule,
    TableModule,
  ],
})
export class AIRoleplayModule {}
