import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { aiRoleplay } from 'app/api/models/ai-roleplay/ai-roleplay';
import { aiRoleplayConversation } from 'app/api/models/ai-roleplay/ai-roleplayConversation';
import { aiRoleplayConversationDto } from 'app/api/models/ai-roleplay/ai-roleplayConversationDto';
import { AIRoleplayDto } from 'app/api/models/ai-roleplay/ai-roleplayDto';
@Injectable({
  providedIn: 'root',
})
export class AIRoleplayService {
  private apiUrl = '/api/ai-roleplay';
  private conversation_API = '/api/ai-roleplay/conversation';

  constructor(private http: HttpClient) {}

  createAIRoleplay(airoleplay: Partial<aiRoleplay>): Observable<aiRoleplay> {
    return this.http.post<aiRoleplay>(this.apiUrl, airoleplay);
  }

  getAllRoleplays(): Observable<aiRoleplay[]> {
    return this.http.get<aiRoleplay[]>(this.apiUrl);
  }

  getRoleplaysForAdmin(): Observable<aiRoleplay[]> {
    return this.http.get<aiRoleplay[]>(`${this.apiUrl}/admin`);
  }

  updateAIRoleplay(id: string, roleplay: Partial<aiRoleplay>) {
    return this.http.put(`${this.apiUrl}/${id}`, roleplay);
  }

  getRoleplayById(id: string): Observable<aiRoleplay> {
    return this.http.get<aiRoleplay>(`${this.apiUrl}/${id}`);
  }
  createAIRoleplayConversaion(
    airoleplay: aiRoleplayConversationDto
  ): Observable<aiRoleplayConversationDto> {
    return this.http.post<aiRoleplayConversationDto>(
      this.conversation_API,
      airoleplay
    );
  }
  getConversation(RoleplayId: string): Observable<aiRoleplayConversationDto[]> {
    return this.http.get<aiRoleplayConversationDto[]>(
      `${this.conversation_API}/${RoleplayId}`
    );
  }
}
