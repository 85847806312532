import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/guards/auth.guard';
import { MediaLibraryComponent } from '../shared/components/media-library/media-library/media-library.component';
import { NewsComponent } from './pages/news/news.component';
import { NewsAdminComponent } from './pages/news-admin/news-admin.component';
import { NewsEditComponent } from './pages/news-edit/news-edit.component';
import { NewsInfoComponent } from './pages/news-info/news-info.component';
import { UserRoles } from 'app/state/auth.repository';

const routes: Routes = [
  {
    path: '',
    component: NewsComponent,
    canActivate: [AuthGuard],
    data: {
      authorize: [UserRoles.TenantAdmin, UserRoles.User],
    },
  },
  {
    path: 'admin',
    component: NewsAdminComponent,
    canActivate: [AuthGuard],
    data: {
      authorize: [UserRoles.TenantAdmin],
    },
  },
  {
    path: 'admin/new',
    component: NewsEditComponent,
    canActivate: [AuthGuard],
    data: {
      authorize: [UserRoles.TenantAdmin],
    },
  },
  {
    path: 'admin/:id',
    component: NewsEditComponent,
    canActivate: [AuthGuard],
    data: {
      authorize: [UserRoles.TenantAdmin],
    },
  },
  {
    path: ':id',
    component: NewsInfoComponent,
    canActivate: [AuthGuard],
    data: {
      authorize: [UserRoles.TenantAdmin, UserRoles.User],
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NewsRoutingModule {}
