import { MediaLibraryFileDto } from '../mediaLibrary/mediaLibraryFileDto';

export class NewsCreateEditDetailsDto {
  id?: string;
  title: string = '';
  description?: string;
  previewMediaLibraryImage?: MediaLibraryFileDto;
  videoSoundUrl: string = '';
  videoSoundMediaLibraryFile?: MediaLibraryFileDto;
  userGroupIds: string[] = [];
  createdAt: Date = new Date();
}
