import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import {
  UserGroup,
  UserGroupsRepository,
} from 'app/state/usergroup.repository';
import { DatalistOption } from 'app/state/abstract/base.repository';
import { UserGroupsService } from 'app/api/services/usergroup.service';
import { MediaLibraryFileDto } from 'app/api/models/mediaLibrary/mediaLibraryFileDto';
import { NewsCreateEditDetailsDto } from 'app/api/models/news/newsCreateDto';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-news-form',
  templateUrl: './news-form.component.html',
  styleUrls: ['./news-form.component.scss'],
})
export class NewsFormComponent implements OnInit {
  @Input() set groups(value: UserGroup[] | null) {
    if (!value) this.userGroupsOptions = null;
    else {
      this.userGroupsOptions = value.map((x) => ({
        value: x.id,
        label: x.name,
      }));
    }
  }

  @Input() editCreateNews: NewsCreateEditDetailsDto =
    new NewsCreateEditDetailsDto();
  @Output() newsSubmit = new EventEmitter<NewsCreateEditDetailsDto>();

  public attachedMediaLibraryFiles: MediaLibraryFileDto[] = [];
  public previewImageMediaLibraryFile?: MediaLibraryFileDto;
  public videoSoundMediaLibraryFile?: MediaLibraryFileDto;

  editForm: UntypedFormBuilder | any;
  userGroupsOptions: DatalistOption[] | null = null;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public userGroupsService: UserGroupsService,
    public userGroupsRepository: UserGroupsRepository,
    public sanitizer: DomSanitizer
  ) {
    this.editForm = this.formBuilder.group({
      title: [this.editCreateNews.title, Validators.required],
      description: this.editCreateNews.description,
      userGroupIds: [this.editCreateNews.userGroupIds],
      videoSoundUrl: [this.editCreateNews.videoSoundUrl],
    });
  }

  ngOnInit() {
    this.setNewsForPreview();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['editCreateNews'] && changes['editCreateNews'].currentValue) {
      this.updateEntityData();
    }
  }

  private updateEntityData(): void {
    this.editForm.patchValue({
      title: this.editCreateNews.title,
      description: this.editCreateNews.description,
      userGroupIds: this.editCreateNews.userGroupIds,
      videoSoundUrl: this.editCreateNews.videoSoundUrl,
    });
    this.previewImageMediaLibraryFile =
      this.editCreateNews.previewMediaLibraryImage;
    this.videoSoundMediaLibraryFile =
      this.editCreateNews.videoSoundMediaLibraryFile;
  }

  onPreviewImageMediaLibraryFileSelected(file: MediaLibraryFileDto) {
    this.previewImageMediaLibraryFile = file;
    this.editCreateNews.previewMediaLibraryImage = file;
  }

  onPreviewImageMediaLibraryFileRemoved(file: MediaLibraryFileDto) {
    this.previewImageMediaLibraryFile = undefined;
    this.editCreateNews.previewMediaLibraryImage = undefined;
  }

  onVideoSoundMediaLibraryFileSelected(file: MediaLibraryFileDto) {
    this.videoSoundMediaLibraryFile = file;
    this.editForm.get('videoSoundUrl')?.disable();
    this.editCreateNews.videoSoundMediaLibraryFile = file;

    this.editCreateNews.videoSoundMediaLibraryFile.sanitazedFileUrl =
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.editCreateNews.videoSoundMediaLibraryFile.fileUrl
      );
  }

  onVideoSoundMediaLibraryFileRemoved(file: MediaLibraryFileDto) {
    this.videoSoundMediaLibraryFile = undefined;
    this.editCreateNews.videoSoundMediaLibraryFile =
      this.videoSoundMediaLibraryFile;
    this.editForm.get('videoSoundUrl')?.enable();
  }

  updateValue(control: string, value: any) {
    const controlObject = this.editForm?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
  }

  onSubmit() {
    if (this.editForm && !this.editForm.valid) {
      this.editForm.markAllAsTouched();
      return;
    }

    const title = this.editForm.value?.title;
    const videoSoundUrlString = this.editForm.value?.videoSoundUrl;
    const userGroupIds = this.editForm.value?.userGroupIds;
    const description = this.editForm.value?.description;

    this.editCreateNews.title = title;
    this.editCreateNews.description = description;
    this.editCreateNews.userGroupIds = userGroupIds;
    this.editCreateNews.videoSoundUrl = videoSoundUrlString;

    this.newsSubmit.emit(this.editCreateNews);
  }

  setNewsForPreview() {
    this.editForm.valueChanges.subscribe((data: any) => {
      this.editCreateNews.title = data.title;
      this.editCreateNews.description = data.description;
      this.editCreateNews.videoSoundUrl = data.videoSoundUrl;
    });
  }
}
