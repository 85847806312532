<div style="width: 100%">
  <div class="content-area">
    <div style="display: flex">
      <button
        [disabled]="selectionDisabled"
        type="button"
        (click)="selectAttachedMediaLibraryFiles()"
        class="button btn btn-primary btn-media nogrow"
      >
        <img
          src="assets/img/fileInptIco.svg"
          class="mx-1"
          alt="Image Icon"
          style="width: 25px; height: 25px"
        />
      </button>
      <div class="ps-2 flex-grow-1">
        <input
          [disabled]="true"
          placeholder="{{ 'select' | transloco }} {{ 'File' | transloco }}"
          class="inpt w-100 h-100"
        />
      </div>
    </div>
    <div class="ms-3 mt-4" style="display: flex">
      <app-media-library-file-icon
        *ngFor="let mediaLibraryFile of mediaLibraryFiles"
        class="ms-3"
        [mediaLibraryFileDto]="mediaLibraryFile"
        [allowFileRemoving]="true"
        (removeFile)="onFileRemove($event)"
      ></app-media-library-file-icon>
    </div>
  </div>
</div>
