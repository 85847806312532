import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { NewsEditComponent } from './pages/news-edit/news-edit.component';
import { NewsFormComponent } from './components/news-form/news-form.component';
import { NewsComponent } from './pages/news/news.component';
import { NewsAdminComponent } from './pages/news-admin/news-admin.component';
import { NewsInfoComponent } from './pages/news-info/news-info.component';
import { NewsTabComponent } from './components/news-tab/news-tab.component';
import { TranslocoRootModule } from 'app/transloco-root.module';
import { NewsRoutingModule } from './news-routing.module';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TooltipModule } from 'primeng/tooltip';
import { NewsPreviewComponent } from './pages/news-preview/news-preview.component';

@NgModule({
  declarations: [
    NewsEditComponent,
    NewsFormComponent,
    NewsComponent,
    NewsInfoComponent,
    NewsAdminComponent,
    NewsTabComponent,
    NewsPreviewComponent,
  ],
  imports: [
    NewsRoutingModule,
    CommonModule,
    TranslocoRootModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    TableModule,
    ButtonModule,
    DropdownModule,
    InputTextModule,
    TooltipModule,
    SplitButtonModule,
    TagModule,
    SelectButtonModule,
    RadioButtonModule,
    InputSwitchModule,
    ToggleButtonModule,
    DialogModule,
    DynamicDialogModule,
    InputTextModule,
  ],
})
export class NewsModule {}
