<div style="display: flex">
  <div style="width: 100%">
    <form
      [formGroup]="editForm"
      (ngSubmit)="onSubmit()"
      enctype="multipart/form-data"
    >
      <div class="form-group form-floating mb-3">
        <div
          class="form-floating mb-4"
          [appValidateState]="editForm.get('title')"
        >
          <div class="inpt-title">{{ "title" | transloco }}</div>
          <input
            id="title"
            formControlName="title"
            type="text"
            class="inpt"
            placeholder="{{ 'title' | transloco }}"
            autocomplete="off"
          />
        </div>
        <!---------------------------------------------------------------------------------------------- -->
        <div class="inpt-title">{{ "previewImage" | transloco }}</div>

        <app-media-library-multiple-files-selector
          [mediaLibraryFiles]="[previewImageMediaLibraryFile!]"
          (onFileSelected)="onPreviewImageMediaLibraryFileSelected($event)"
          (onFileRemoved)="onPreviewImageMediaLibraryFileRemoved($event)"
          [allowImagesSelection]="true"
        >
        </app-media-library-multiple-files-selector>

        <!---------------------------------------------------------------------------------------------- -->

        <div class="inpt-title mt-3">{{ "Video/Sound" | transloco }}</div>

        <div class="mb-3 mt-2">
          <input
            formControlName="videoSoundUrl"
            placeholder="{{ 'video' | transloco }} {{ 'url' | transloco }}"
            class="inpt w-100 h-100"
          />
        </div>

        <app-media-library-multiple-files-selector
          [mediaLibraryFiles]="[videoSoundMediaLibraryFile!]"
          (onFileSelected)="onVideoSoundMediaLibraryFileSelected($event)"
          (onFileRemoved)="onVideoSoundMediaLibraryFileRemoved($event)"
          [selectionDisabled]="editForm.value?.videoSoundUrl"
          [allowVideosSelection]="true"
          [allowSoundsSelection]="true"
        >
        </app-media-library-multiple-files-selector>
        <!------------------------------------------------------------------------------------------------>

        <div
          class="form-group form-floating mb-3 mt-3"
          [appValidateState]="editForm.get('description')"
        >
          <div class="inpt-title">{{ "description" | transloco }}</div>
          <p-editor
            [placeholder]="'Description'"
            [style]="{ minHeight: '150px' }"
            formControlName="description"
          >
            <ng-template pTemplate="header">
              <ng-template [ngTemplateOutlet]="editorToolbar"></ng-template>
            </ng-template>
          </p-editor>
        </div>

        <div class="form-group form-floating mb-3">
          <app-datalist-select
            [activeValue]="editForm?.value?.userGroupIds"
            [options]="userGroupsOptions"
            (activeValueChange)="updateValue('userGroupIds', $event)"
            [multiple]="true"
            label="{{ 'userGroups' | transloco }}"
          ></app-datalist-select>
        </div>
      </div>
      <div class="form-group form-floating h-100 pb-3 d-grid">
        <button type="submit" class="btn btn-orange shadow-sm">
          {{ "save" | transloco }}
        </button>
      </div>
    </form>
  </div>
  <div *ngIf="true" class="divider hide-on-small-screen" id="divider"></div>
  <div *ngIf="true" class="hide-on-small-screen ms-2" style="width: 100%">
    <app-news-preview [news]="editCreateNews"></app-news-preview>
  </div>
</div>

<ng-template #editorToolbar>
  <span class="ql-formats">
    <select class="ql-header" aria-label="Header">
      <option selected></option>
      <option value="1">Heading 1</option>
      <option value="2">Heading 2</option>
    </select>

    <button type="button" class="ql-bold" aria-label="Bold"></button>
    <button type="button" class="ql-italic" aria-label="Italic"></button>

    <button
      type="button"
      class="ql-list"
      value="ordered"
      aria-label="Numbered List"
    ></button>
    <button
      type="button"
      class="ql-list"
      value="bullet"
      aria-label="Bullet List"
    ></button>
  </span>
</ng-template>
