<div class="row page-header d-flex justify-content-center me-sm-1 ms-sm-1">
  <div class="col-12 col-lg-11 col-xl-10 max-950 row p-md-2 p-lg-0 p-0">
    <div class="col-6 mt-sm-4 mb-sm-3 p-sm-0 ps-2">
      <h2 class="page-title" i18n="Label users">
        {{ "aiRoleplayAdminPage" | transloco }}
      </h2>
    </div>
    <div class="col-6 mt-sm-4 mb-sm-3 p-0 d-flex justify-content-end">
      <div class="d-flex">
        <a
          routerLink="/ai-roleplay/admin/new"
          class="btn btn-lg orange-dark text-white"
        >
          <img src="assets/img/plus_add.svg" />
          <span class="text-btn-add">{{ "add" | transloco }}</span>
        </a>
      </div>
    </div>

    <p-table #dt1 [value]="filteredAIRoleplay!" [globalFilterFields]="['name']">
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="name" class="col-2">
            {{ "name" | transloco }} <p-sortIcon field="name"></p-sortIcon>
          </th>
          <th pSortableColumn="userGroups" class="col-3">
            {{ "userGroup" | transloco }}
            <p-sortIcon field="userGroups"></p-sortIcon>
          </th>
          <th pSortableColumn="prompt" class="col-4">
            {{ "prompt" | transloco }}<p-sortIcon field="prompt"></p-sortIcon>
          </th>
          <th pSortableColumn="description" class="col-3">
            {{ "description" | transloco
            }}<p-sortIcon field="description"></p-sortIcon>
          </th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-roleplay>
        <tr>
          <td
            [routerLink]="['/ai-roleplay/admin', roleplay.id]"
            style="cursor: pointer"
          >
            {{ roleplay.name }}
          </td>
          <td>
            <span
              *ngIf="roleplay.userGroupNames && roleplay.userGroupNames.length"
            >
              <span
                *ngFor="let group of roleplay.userGroupNames; let last = last"
              >
                {{ group }}
                <span *ngIf="!last">, </span>
              </span>
            </span>
          </td>
          <td class="col-4">
            <p class="text-break splice-text">{{ roleplay.prompt }}</p>
          </td>
          <td>{{ roleplay.description }}</td>
          <td>
            <div style="display: flex">
              <div>
                <a
                  class="btn btn-md btn-link w-100"
                  [routerLink]="['/ai-roleplay/admin', roleplay.id]"
                >
                  <img src="assets/img/edit.svg" />
                </a>
              </div>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
