<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="inpt-title">{{ "name" | transloco }}</div>
  <input
    type="text"
    class="inpt"
    placeholder="{{ 'add-name' }}"
    autocomplete="off"
    formControlName="name"
  />
  <div class="inpt-title">{{ "prompt" | transloco }}</div>
  <input
    type="text"
    class="inpt mb-3"
    placeholder="{{ 'add-prompt' }}"
    autocomplete="off"
    formControlName="prompt"
  />
  <div class="inpt-title">{{ "description" | transloco }}</div>
  <input
    type="text"
    class="inpt mb-3"
    placeholder="{{ 'add-description' }}"
    autocomplete="off"
    formControlName="description"
  />
  <app-datalist-select
    [activeValue]="form.value?.userGroupIds"
    id="userGroupsSelect"
    [options]="userGroupsOptions"
    [multiple]="true"
    (activeValueChange)="updateValue('userGroupIds', $event)"
  ></app-datalist-select>
  <button type="submit" class="btn btn-orange shadow-sm mt-3">
    {{ "save" }}
  </button>
  <!-- <app-datalist-select
  [multiple]="false"
  [label]="'Select Status'"
  [(activeValue)]="selectedStatusId"
  [options]="statusOptions"
  (activeValueChange)="updateValue('status', $event)"
></app-datalist-select> -->
</form>
