import { Component, HostListener, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AIRoleplayService } from 'app/state/ai-roleplay.service';
import { aiRoleplay } from 'app/api/models/ai-roleplay/ai-roleplay';
import { aiRoleplayConversationDto } from 'app/api/models/ai-roleplay/ai-roleplayConversationDto';

@Component({
  selector: 'app-ai-roleplay-conversation-page',
  templateUrl: './ai-roleplay-conversation-page.component.html',
  styleUrls: ['./ai-roleplay-conversation-page.component.scss'],
})
export class AiRoleplayConversationPageComponent {
  roleplay: Partial<aiRoleplay> | null = null;
  id: string = '';
  submitErrors: string[] | null = null;
  conversations: any[] = [];
  newMessage = '';
  constructor(
    private roleplayService: AIRoleplayService,
    private route: ActivatedRoute,
    private router: Router
  ) {}
  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      const idFromParams = params.get('id');
      if (idFromParams) {
        this.id = idFromParams;
        this.loadRoleplay(this.id);
        this.refreshData();
        console.log(this.id);
      } else {
        this.id = '';
        console.error('ID is missing in the route params');
      }
    });
  }
  refreshData() {
    if (!this.id) {
      console.error('Conversation ID is missing');
      return;
    }
    console.log(this.id);
    this.roleplayService.getConversation(this.id).subscribe(
      (data) => {
        this.conversations = data;
        console.log('Data loaded:', data);
      },
      (error) => {
        console.error('Error loading data:', error);
      }
    );
  }

  loadRoleplay(id: string): void {
    this.roleplayService.getRoleplayById(id).subscribe({
      next: (data) => (this.roleplay = data),
      error: (err) => (this.submitErrors = [err.message]),
    });
  }
  sendMessage(event: Event) {
    event.preventDefault();

    console.log('Message before sending: ', this.newMessage);
    if (!this.newMessage.trim()) {
      return;
    }
    const newMessage: aiRoleplayConversationDto = {
      message: this.newMessage,
      AI_RoleplayId: this.id,
    };

    this.roleplayService.createAIRoleplayConversaion(newMessage).subscribe({
      next: (res) => {
        console.log('Message sent successfully');
        this.newMessage = '';
        this.refreshData();
      },
      error: (err) => {
        console.error('Error sending message:', err);
      },
    });
  }
}
