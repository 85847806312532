import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AIRoleplayService } from 'app/state/ai-roleplay.service';
import { aiRoleplay } from 'app/api/models/ai-roleplay/ai-roleplay';

@Component({
  selector: 'app-ai-roleplay-edit',
  templateUrl: './ai-roleplay-edit.component.html',
  styleUrls: ['./ai-roleplay-edit.component.scss'],
})
export class AiRoleplayEditComponent implements OnInit {
  roleplay: Partial<aiRoleplay> | null = null;
  id: string | null = null;
  submitErrors: string[] | null = null;

  constructor(
    private roleplayService: AIRoleplayService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.id = params.get('id');
      if (this.id && this.id !== 'new') {
        this.loadRoleplay(this.id);
      }
    });
  }

  loadRoleplay(id: string): void {
    this.roleplayService.getRoleplayById(id).subscribe({
      next: (data) => (this.roleplay = data),
      error: (err) => (this.submitErrors = [err.message]),
    });
  }

  onRoleplaySubmit(roleplayData: Partial<aiRoleplay>) {
    if (this.id === 'new') {
      this.createRoleplay(roleplayData);
    } else {
      this.updateRoleplay(roleplayData);
    }
  }

  createRoleplay(roleplay: Partial<aiRoleplay>) {
    this.roleplayService.createAIRoleplay(roleplay).subscribe({
      next: () => {
        console.log('Новий AI Roleplay створено успішно');
        this.router.navigate(['/ai-roleplay/admin']);
      },
      error: (error) => {
        console.error('Помилка при створенні запису:', error);
      },
    });
  }

  updateRoleplay(roleplay: Partial<aiRoleplay>) {
    console.log('Roleplay data:', roleplay); // Додайте лог
    if (this.id) {
      this.roleplayService.updateAIRoleplay(this.id, roleplay).subscribe({
        next: () => {
          console.log('AI Roleplay оновлено успішно');
          this.router.navigate(['/ai-roleplay/admin']);
        },
        error: (error) => {
          console.error('Помилка при оновленні запису:', error);
        },
      });
    }
  }
}
