<form
  *ngIf="form"
  [formGroup]="form"
  (ngSubmit)="onSubmit()"
  enctype="multipart/form-data"
>
  <div class="row mb-2">
    <div class="col">
      <h6 class="my-1" i18n="Basic info label">
        {{ "basicInfo" | transloco }}
      </h6>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <div class="inpt-title">{{ "name" | transloco }}</div>
        <input
          formControlName="name"
          type="text"
          class="inpt"
          placeholder="Name"
        />
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <div class="inpt-title">{{ "surname" | transloco }}</div>
        <input
          formControlName="surname"
          type="text"
          class="inpt"
          placeholder="Surname"
        />
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div
        [appValidateState]="form.get('email')"
        class="form-group form-floating mb-3"
      >
        <div class="inpt-title">{{ "email" | transloco }}</div>
        <input
          formControlName="email"
          type="email"
          class="inpt"
          placeholder="Email"
        />
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <div class="inpt-title">{{ "phone" | transloco }}</div>
        <input
          formControlName="phone"
          type="tel"
          class="inpt"
          placeholder="Phone number"
        />
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <app-media-library-multiple-files-selector
        [allowImagesSelection]="true"
        [mediaLibraryFiles]="[userImageMediaLibraryFile!]"
        [uploadOnlyPrivateFilesModeActive]="true"
        (onFileSelected)="onUserImageMediaLibraryFileSelected($event)"
        (onFileRemoved)="onUserImageMediaLibraryFileRemoved($event)"
      ></app-media-library-multiple-files-selector>
    </div>

    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <div class="inpt-title">{{ "location" | transloco }}</div>
        <input
          formControlName="location"
          type="text"
          class="inpt"
          placeholder="Location"
        />
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <div class="inpt-title">{{ "title" | transloco }}</div>
        <input
          formControlName="title"
          type="text"
          class="inpt"
          placeholder="Title"
        />
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <div class="inpt-title">{{ "birthDate" | transloco }}</div>
        <input
          formControlName="birthday"
          type="date"
          class="inpt"
          placeholder="Date of birth"
        />
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <app-datalist-select
          id="langSelect"
          [options]="langOptions"
          [multiple]="false"
          [(activeValue)]="form.value.language"
          (activeValueChange)="updateValue('language', $event)"
          label="{{ 'language' | transloco }}"
        ></app-datalist-select>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4" *ngIf="showPasswordField">
      <div
        [appValidateState]="form.get('password')"
        class="form-group form-floating mb-3"
      >
        <div class="inpt-title">{{ "password" | transloco }}</div>
        <input
          formControlName="password"
          type="password"
          class="inpt"
          placeholder="Password"
          required
        />
      </div>
    </div>
    <div
      class="col-12 col-md-6 col-lg-4"
      *ngIf="
        tenantOptions &&
        tenantOptions.length &&
        !generalUserData.tenantSet &&
        !activeTenantId
      "
    >
      <div
        class="form-group form-floating mb-3"
        [class.is-invalid]="
          form.get('tenantIds')?.hasError('required') &&
          form.get('tenantIds')?.touched
        "
      >
        <app-datalist-select
          id="tenantSelect"
          [options]="tenantOptions"
          [multiple]="true"
          [(activeValue)]="form.value.tenantIds"
          (activeValueChange)="updateValue('tenantIds', $event)"
          label="Tenant"
        ></app-datalist-select>
      </div>
    </div>
    <div
      class="col-12 col-md-6 col-lg-4"
      *ngIf="generalUserData.tenantSet && mode != 'profile'"
    >
      <div
        class="form-group form-floating mb-3"
        [class.is-invalid]="
          form.get('userGroupsIds')?.hasError('required') &&
          form.get('userGroupsIds')?.touched
        "
      >
        <app-datalist-select
          id="userGroupsSelect"
          [options]="userGroupsOptions"
          [multiple]="true"
          [(activeValue)]="form.value.userGroupsIds"
          (activeValueChange)="updateValue('userGroupsIds', $event)"
          label="User Groups"
        ></app-datalist-select>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4" *ngIf="isActiveTenantSet">
      <div class="form-group form-floating mb-3">
        <app-datalist-select
          id="userCoaches"
          [options]="userCoachOptions"
          [multiple]="true"
          [(activeValue)]="form.value.userCoachIds"
          (activeValueChange)="updateValue('userCoachIds', $event)"
          label="User Coaches"
        ></app-datalist-select>
      </div>
    </div>

    <div
      *ngIf="authRepo.isAnyAdmin$ | async"
      class="col-3 col-md-6 col-lg-4 form-group form-floating mb-md-0 mb-3 d-flex align-items-center"
    >
      <input
        type="checkbox"
        formControlName="isCoach"
        class="form-check-input m-0 me-1"
      />
      {{ "coach" | transloco }}
    </div>
  </div>
  <div class="row mb-2" *ngIf="roleOptions">
    <div class="col">
      <h6 class="my-1">{{ "roles" | transloco }}</h6>
    </div>
  </div>
  <div class="row mb-3" *ngIf="roleOptions">
    <div class="col">
      <div class="form-group form-floating h-100 pb-3 d-grid">
        <div class="card">
          <ul class="list-group list-group-horizontal bg-none">
            <li
              class="no-outer-border list-group-item flex-fill w-100"
              *ngFor="let role of roleOptions; let roleIndex = index"
              formArrayName="roles"
            >
              <div
                class="row"
                *ngIf="
                  role != 'PartnerManager' || (authRepo.isSuperAdmin$ | async)
                "
              >
                <div class="col-auto pr-0 align-self-center text-end">
                  <div class="form-check form-switch">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      [formControlName]="roleIndex"
                      [attr.id]="'checkbox-' + role"
                    />
                    <label
                      class="form-check-label"
                      [attr.for]="'checkbox-' + role"
                    ></label>
                  </div>
                </div>
                <div class="col">
                  <h6 class="mb-1">{{ ROLE_NAMES[role] }}</h6>
                  <p class="text-muted small">{{ ROLE_DESCRIPTIONS[role] }}</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating h-100 pb-3 d-grid">
        <button type="submit" class="btn btn-orange shadow-sm">
          {{ "save" | transloco }}
        </button>
      </div>
    </div>
  </div>
</form>
