import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';

import { aiRoleplay } from 'app/api/models/ai-roleplay/ai-roleplay';
import { DatalistOption } from 'app/state/abstract/base.repository';
import { UserGroupsService } from 'app/api/services/usergroup.service';
import { AIRoleplayService } from 'app/state/ai-roleplay.service';
import {
  UserGroupsRepository,
  UserGroup,
} from 'app/state/usergroup.repository';

@Component({
  selector: 'app-ai-roleplay-form',
  templateUrl: './ai-roleplay-form.component.html',
  styleUrls: ['./ai-roleplay-form.component.scss'],
})
export class AIRoleplayFormComponent implements OnInit {
  form: FormGroup;
  selectedUserGroupsOptions: string[] = [];
  userGroupsOptions: DatalistOption[] = [];

  groupsOptions: DatalistOption[] | null = null;
  groupsOptionsDeleted: DatalistOption[] | null = null;

  @Input() editedRoleplay: Partial<aiRoleplay> | null = null;
  @Output() roleplaySubmit = new EventEmitter<Partial<aiRoleplay>>();

  @Input() set deletedGroups(value: UserGroup[] | null) {
    this.groupsOptionsDeleted = value
      ? value.map((x) => ({ value: x.id, label: x.name }))
      : null;
  }

  @Input() set groups(value: UserGroup[] | null) {
    this.groupsOptions = value
      ? value.map((x) => ({ value: x.id, label: x.name }))
      : null;
  }

  constructor(
    private fb: FormBuilder,

    public airoleplayService: AIRoleplayService,
    public userGroupsService: UserGroupsService,
    public userGroupsRepository: UserGroupsRepository
  ) {
    this.form = this.fb.group({
      name: ['', Validators.required],
      prompt: ['', Validators.required],
      userGroupIds: [[]],
      description: [''],
    });
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      name: [this.editedRoleplay?.name, Validators.required],
      prompt: [this.editedRoleplay?.prompt, Validators.required],
      userGroupIds: [this.editedRoleplay?.userGroupIds || []],
      description: [this.editedRoleplay?.description],
    });

    this.userGroupsService.loadForDatalist().subscribe((options) => {
      this.userGroupsOptions = options;
      console.log('Завантажені групи користувачів:', options);
    });
  }
  onSubmit() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    const updatedRoleplay: Partial<aiRoleplay> = {
      name: this.form.value.name,
      prompt: this.form.value.prompt,
      userGroupIds: this.form.value.userGroupIds,
      description: this.form.value.description,
    };
    this.roleplaySubmit.emit(updatedRoleplay);
    console.log(updatedRoleplay);
  }

  updateValue(control: string, value: any) {
    const controlObject = this.form.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
  }
}
