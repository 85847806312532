<div class="row page-header d-flex justify-content-center me-sm-1 ms-sm-1">
  <div class="col-12 col-lg-11 col-xl-10 max-950 row p-md-2 p-lg-0 p-0">
    <div class="col-6 mt-sm-4 mb-sm-3 p-sm-0 ps-2">
      <h2 class="page-title" i18n="Label users">
        {{ "aiRoleplayPage" | transloco }}
      </h2>
    </div>
    <p-table #dt1 [value]="filteredAIRoleplay!" [globalFilterFields]="['name']">
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="name">
            {{ "name" | transloco }} <p-sortIcon field="name"></p-sortIcon>
          </th>
          <!-- <th pSortableColumn="userGroups">
            {{ "userGroup" | transloco }}
            <p-sortIcon field="userGroups"></p-sortIcon>
          </th>

          <th pSortableColumn="prompt">
            {{ "prompt" | transloco }}<p-sortIcon field="prompt"></p-sortIcon>
          </th>
          <th></th> -->
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-roleplay>
        <tr>
          <td
            [routerLink]="['/ai-roleplay-conversation/admin', roleplay.id]"
            style="cursor: pointer"
          >
            {{ roleplay.name }}
          </td>
          <!-- <td>
            <span *ngIf="roleplay.userGroups && roleplay.userGroups.length">
              <span *ngFor="let group of roleplay.userGroups">
                {{ group.label }}
                <span
                  *ngIf="
                    group !==
                    roleplay.userGroups[roleplay.userGroups.length - 1]
                  "
                  >,
                </span>
              </span>
            </span>
          </td>
          <td>
            {{ roleplay.prompt }}
          </td> -->
          <td></td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
