import { Component, Input, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NewsCreateEditDetailsDto } from 'app/api/models/news/newsCreateDto';
import { ToolboxDetailsDto } from 'app/api/models/toolbox/toolBoxDetailsDto';
import { Post } from 'app/state/posts.repository';

@Component({
  selector: 'app-news-preview',
  templateUrl: './news-preview.component.html',
  styleUrls: ['./news-preview.component.scss'],
})
export class NewsPreviewComponent {
  @Input() news: NewsCreateEditDetailsDto = new NewsCreateEditDetailsDto();

  sanitazedFileUrl?: SafeResourceUrl;

  constructor(public sanitizer: DomSanitizer) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['news'] && changes['news'].currentValue) {
      if (this.news.videoSoundMediaLibraryFile) {
        this.sanitazedFileUrl = undefined;
        this.news.videoSoundMediaLibraryFile.sanitazedFileUrl =
          this.sanitizer.bypassSecurityTrustResourceUrl(
            this.news.videoSoundMediaLibraryFile.fileUrl
          );
      } else if (this.news.videoSoundUrl) {
        this.sanitazedFileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          this.news.videoSoundUrl
        );
        this.news.videoSoundMediaLibraryFile = undefined;
      }
    }
  }

  openLinkInNewTab(src?: string) {
    if (src) {
      window.open((src = src), '_blank');
    }
  }
}
