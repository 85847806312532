<figure
  [class]="'avatar avatar-' + size + ' rounded-circle'"
  style="
    align-items: center;
    margin: auto;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.7);
  "
>
  <img
    *ngIf="img; else noImage"
    src="{{ img }}"
    onerror="this.style.display='alt'"
    [alt]="name"
  />

  <ng-template #noImage>
    <i class="initials">
      {{
        initialSurName && initialsName
          ? initialsName + initialSurName
          : initialsName
      }}
    </i>
  </ng-template>
</figure>
