<div style="display: flex; justify-content: center">
  <div
    *ngIf="mediaLibraryFileDto && mediaLibraryFileDto.mediaType !== 1"
    class="file-icon-wrapper"
  >
    <div class="file-content-wrapper" [title]="mediaLibraryFileDto.fileName">
      <a
        [href]="mediaLibraryFileDto.fileUrl"
        target="_blank"
        [attr.download]="mediaLibraryFileDto.fileName"
      >
        <img
          class="file-icon"
          mat-card-image
          [src]="
            this.mediaLibraryService.getMediaLibraryFileIconDependsOnType(
              mediaLibraryFileDto
            )
          "
        />
        <div class="file-info" *ngIf="mediaLibraryFileDto">
          <p class="file-name">
            {{ mediaLibraryFileDto.fileName | slice : 0 : 7 }}...
          </p>
        </div>
      </a>
    </div>
  </div>

  <div *ngIf="mediaLibraryFileDto && mediaLibraryFileDto.mediaType === 1">
    <img
      class="mt-2"
      [ngStyle]="{
        'max-width': maxImageWidth + 'px',
        width: '100%',
        'border-radius': '6px'
      }"
      mat-card-image
      [src]="
        this.mediaLibraryService.getMediaLibraryFileIconDependsOnType(
          mediaLibraryFileDto
        )
      "
    />
  </div>

  <div *ngIf="allowFileRemoving && mediaLibraryFileDto">
    <button class="delete-icon" (click)="onDeleteFile()">&#10060;</button>
  </div>
</div>
