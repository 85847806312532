import { Inject, Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { distinctUntilChanged, startWith } from 'rxjs/operators';
import {
  BLOB_STORAGE_TOKEN,
  IBlobService,
  IBlobStorage,
  ISasToken,
  ISpeedSummary,
} from './azureStorage';

@Injectable()
export class BlobStorageService {

  constructor(@Inject(BLOB_STORAGE_TOKEN) private blobStorage: IBlobStorage) {}

  a2appStorageAccessToken: string =
    'st=2023-08-18T12:00:44Z&si=yy&sv=2022-11-02&sr=c&sig=V%2F5MeWbxCVQXkDnrtegob%2FGF7NE5ILyQIXbOOvccanc%3D';

  a2appStorageUri: string = 'https://a2app.blob.core.windows.net/a2app';

  a2appeuropeStorageAccessToken: string =
    '?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2025-01-16T21:54:16Z&st=2023-10-03T12:54:16Z&spr=https,http&sig=Kwupb%2FQWdyTNbSryjn7Hjqo7hxqUvmgLjneCKa1aICA%3D';

  a2appeuropeStorageUri: string = 'https://a2app1europe.blob.core.windows.net';

  uploadToBlobStorage(sasToken: ISasToken, file: File): Observable<number> {
    const customBlockSize = this.getBlockSize(file);
    const options = { blockSize: customBlockSize };
    const blobService = this.createBlobService(
      sasToken.storageAccessToken,
      sasToken.storageUri
    );

    blobService.singleBlobPutThresholdInBytes = customBlockSize;
    return this.uploadFile(blobService, sasToken, file, options);
  }

  private createBlobService(
    accessToken: string,
    blobUri: string
  ): IBlobService {
    return this.blobStorage
      .createBlobServiceWithSas(blobUri, accessToken)
      .withFilter(new this.blobStorage.ExponentialRetryPolicyFilter());
  }

  private uploadFile(
    blobService: IBlobService,
    accessToken: ISasToken,
    file: File,
    options: { blockSize: number }
  ): Observable<number> {
    return new Observable<number>((observer) => {
      const speedSummary = blobService.createBlockBlobFromBrowserFile(
        accessToken.container,
        accessToken.filename,
        file,
        options,
        (error) => this.callback(error, observer)
      );
      speedSummary.on('progress', () => {
        return this.getProgress(speedSummary, observer);
      });
    }).pipe(startWith(0), distinctUntilChanged());
  }

  private getProgress(
    speedSummary: ISpeedSummary,
    observer: Subscriber<number>
  ): void {
    const progress = parseInt(speedSummary.getCompletePercent(2), 10);
    observer.next(progress === 100 ? 99 : progress);
  }

  private callback(error: any, observer: Subscriber<number>): void {
    if (error) {
      observer.error(error);
    } else {
      observer.next(100);
      observer.complete();
    }
  }

  private getBlockSize(file: File): number {
    const size32Mb = 1024 * 1024 * 32;
    const size4Mb = 1024 * 1024 * 4;
    const size512Kb = 1024 * 512;

    return file.size > size32Mb ? size4Mb : size512Kb;
  }

  openFileFromBlob(filePath: string, isEuropeBlob: boolean = true) {
    filePath = this.getFilePathFromBlob(filePath, isEuropeBlob);
    window.open(filePath, '_blank');
  }

  getFilePathFromBlob(filePath: string, isEuropeBlob: boolean = true) {
    if (isEuropeBlob) {
      return `${this.a2appeuropeStorageUri}/a2app/${
        filePath + this.a2appeuropeStorageAccessToken
      }`;
    } else {
      return `${this.a2appStorageUri}/a2app/${filePath}`;
    }
  }

  getCompressedImagePath(
    imagePath: string,
    width: number,
    isEuropeBlob: boolean,
    isCompressedExcist?: boolean
  ) {
    if (isCompressedExcist) {
      return `${this.a2appeuropeStorageUri}/a2app/compress${width}/compressed-${width}wth-${imagePath}${this.a2appeuropeStorageAccessToken}`;
    } else if (isEuropeBlob) {
      return `${this.a2appeuropeStorageUri}/a2app/${
        imagePath + this.a2appeuropeStorageAccessToken
      }`;
    } else {
      return `${this.a2appStorageUri}/a2app/${imagePath}`;
    }
  }

  getFileName(fileName: string) {
    return fileName.slice(36);
  }

  getUserImageName(imagePath?: string) {
    if (!imagePath) {
      return '';
    } else if (imagePath.includes('Resources')) {
      return 'User image';
    } else {
      return this.getFileName(imagePath);
    }
  }
}
