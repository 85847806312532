import { Component, HostListener, ViewChild } from '@angular/core';
import { AIRoleplayDto } from 'app/api/models/ai-roleplay/ai-roleplayDto';
import { AuthRepository } from 'app/state/auth.repository';
import { AIRoleplayService } from 'app/state/ai-roleplay.service';
@Component({
  selector: 'app-ai-roleplay-page-admin',
  templateUrl: './ai-roleplay-page-admin.component.html',
  styleUrls: ['./ai-roleplay-page-admin.component.scss'],
})
export class AiRoleplayPageAdminComponent {
  isLoading: boolean = true;
  filteredAIRoleplay: AIRoleplayDto[] | null = null;
  tenantId: string | null = null;
  airoleplays: AIRoleplayDto[] = [];
  innerWidth = 0;
  constructor(
    public auth: AuthRepository,
    private service: AIRoleplayService
  ) {}
  ngOnInit(): void {
    this.loadRoleplays();
  }

  loadRoleplays() {
    this.service.getRoleplaysForAdmin().subscribe((data: AIRoleplayDto[]) => {
      this.airoleplays = data;
      this.filteredAIRoleplay = data;
      this.isLoading = false;
    });
  }
}
