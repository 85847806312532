import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/guards/auth.guard';
import { ApphomelayoutComponent } from '../modules/core/layouts/apphomelayout/apphomelayout.component';

import { UserRoles } from '../state/auth.repository';
import { TenantFeatures } from '../state/feature.repository';
import { AIRoleplayPageComponent } from 'app/modules/AI_Roleplay/page/ai-roleplay-page/ai-roleplay-page.component';
import { AIRoleplayFormComponent } from 'app/modules/AI_Roleplay/components/ai-roleplay-form/ai-roleplay-form.component';
import { AiRoleplayConversationPageComponent } from 'app/modules/AI_Roleplay/page/ai-roleplay-conversation-page/ai-roleplay-conversation-page.component';
import { AiRoleplayPageAdminComponent } from 'app/modules/AI_Roleplay/page-admin/ai-roleplay-page-admin/ai-roleplay-page-admin.component';
import { AiRoleplayEditComponent } from 'app/modules/AI_Roleplay/page-admin/ai-roleplay-edit/ai-roleplay-edit.component';
const routes: Routes = [
  {
    path: '',
    component: ApphomelayoutComponent,
    canActivateChild: [AuthGuard],

    children: [
      {
        path: 'ai-roleplay/main',
        component: AIRoleplayPageComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin, UserRoles.User],
          feature: TenantFeatures.AIRoleplay,
        },
      },
      {
        path: 'ai-roleplay-conversation/admin/:id',
        component: AiRoleplayConversationPageComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin],
          feature: TenantFeatures.AIRoleplay,
        },
      },
      {
        path: 'ai-roleplay/admin/:id',
        component: AiRoleplayEditComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin],
          feature: TenantFeatures.AIRoleplay,
        },
      },
      {
        path: 'ai-roleplay/admin',
        component: AiRoleplayPageAdminComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin],
          feature: TenantFeatures.AIRoleplay,
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AIRoleplayRoutingModule {
  navigate(arg0: string[]) {
    throw new Error('Method not implemented.');
  }
  paramMap: any;
}
