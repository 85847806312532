import { Injectable } from '@angular/core';
import { Store, createState, withProps, select } from '@ngneat/elf';
import {
  withEntities,
  selectAllEntities,
  upsertEntities,
  selectEntity,
} from '@ngneat/elf-entities';
import {
  createRequestsCacheOperator,
  createRequestsStatusOperator,
  selectRequestStatus,
  updateRequestCache,
  updateRequestsStatus,
  withRequestsCache,
  withRequestsStatus,
} from '@ngneat/elf-requests';
import { combineLatest, map } from 'rxjs';
import {
  sortBy,
  SortOption,
  SortState,
} from 'app/modules/shared/pipes/sort.pipe';
import { UserRoles } from './auth.repository';
import { BaseRepository } from './abstract/base.repository';
import { Tenant } from './tenants.repository';
import { UserGroup } from './usergroup.repository';
import { IOutOfOfficeStatus } from './out-of-office-status.repository';
import { MediaLibraryFileDto } from 'app/api/models/mediaLibrary/mediaLibraryFileDto';

export interface User {
  id: string;
  email: string;
  phoneNumber: string;
  name: string;
  surname: string;
  title: string;
  location: string;
  language: string;
  birthday?: Date;
  tenantIds?: string[];
  tenants?: Tenant[];
  createdAt: Date;
  imagePath: string;
  roles: UserRoles[];
  deletedAt?: Date;
  userGroupsIds?: string[];
  userGroups?: UserGroup[];
  userCoachIds: string[];
  outOfOfficeStatuses: IOutOfOfficeStatus[];
  isCoach: boolean;
  imageMediaFile?: MediaLibraryFileDto;
}

export interface ImportedUser extends User {
  isUserCoach: string;
  coachEmails: string;
  isEdited: boolean;
  hasErrors: boolean;
  password: string;
  userGroupIdentifiers: string;
}

export const userSortOptions: SortOption[] = [
  { label: $localize`:Sort label Name:Name`, property: 'name' },
  { label: $localize`:Sort label Surname:Surname`, property: 'surname' },
  { label: $localize`:Sort label Date:Date`, property: 'createdAt' },
];

export interface errorInterface {
  errorMessage: string;
  errorState: boolean;
}

export interface UserProps {
  sortBy: SortState;
}
const { state, config } = createState(
  withProps<UserProps>({
    sortBy: { parameter: userSortOptions[0], direction: 'asc' },
  }),
  withEntities<User>(),
  withRequestsCache(),
  withRequestsStatus()
);

const store = new Store({ name: 'allUsers', state, config });
const coachStore = new Store({ name: 'Coaches', state, config });
@Injectable({ providedIn: 'root' })
export class UsersRepository extends BaseRepository<User> {
  users$ = store.pipe(selectAllEntities());
  coaches$ = coachStore.pipe(selectAllEntities());
  clearStore() {
    store.reset();
  }
  setUsers(users: User[]) {
    if (users) {
      store.reset();
      store.update(
        updateRequestCache(store.name),
        upsertEntities(users),
        updateRequestsStatus([store.name], 'success')
      );
    }
  }

  setCoaches(users: User[]) {
    if (users) {
      coachStore.reset();
      coachStore.update(
        updateRequestCache(coachStore.name),
        upsertEntities(users),
        updateRequestsStatus([coachStore.name], 'success')
      );
    }
  }

  constructor() {
    super('users', userSortOptions);
  }
}
