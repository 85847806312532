import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Post } from 'app/state/posts.repository';
import { PostsService } from 'app/state/posts.service';
import {
  UserGroup,
  UserGroupsRepository,
} from 'app/state/usergroup.repository';
import { UserGroupsService } from 'app/api/services/usergroup.service';
import { NewsCreateEditDetailsDto } from 'app/api/models/news/newsCreateDto';

@Component({
  selector: 'app-news-edit',
  templateUrl: './news-edit.component.html',
  styleUrls: ['./news-edit.component.scss'],
})
export class NewsEditComponent implements OnInit {
  post: NewsCreateEditDetailsDto = new NewsCreateEditDetailsDto();
  createMod: boolean = false;

  id: string | null = null;
  submitErrors: string[] | null = null;

  constructor(
    private service: PostsService,
    private router: Router,
    private route: ActivatedRoute,
    public userGroupsService: UserGroupsService,
    public userGroupsRepository: UserGroupsRepository
  ) {}
  userGroup: UserGroup | null = null;
  groups: UserGroup[] | null = null;
  deletedGroups: UserGroup[] | null = null;

  ngOnInit(): void {
    this.route.paramMap.subscribe({
      next: (value: any) => {
        this.id = value.get('id')!;
        if (this.id) {
          this.loadEntity(this.id);
        } else {
          this.createMod = true;
        }
        this.userGroupsService
          .loadAllDeleted()
          .subscribe((x) => (this.deletedGroups = x));
        this.userGroupsService.loadAll().subscribe((x) => (this.groups = x));
      },
    });
  }

  loadEntity(id: string) {
    this.service.getForEditById(id).subscribe((x) => {
      this.post = x;
    });
  }

  updatePost(news: NewsCreateEditDetailsDto) {
    this.submitErrors = null;
    let updateResult: Observable<Object> | undefined;
    if (this.createMod) {
      updateResult = this.service.create(news);
    } else if (!this.createMod) {
       updateResult = this.service.updateNews(this.post);
    }
    if (updateResult) {
      updateResult.subscribe({
        complete: () => {
          this.router.navigate(['/posts/admin']);
        },
        error: (data) => (this.submitErrors = data),
      });
    }
  }
}
