<div class="row page-header d-flex justify-content-center me-sm-1 ms-sm-1">
  <div class="col-12 col-lg-11 col-xl-10 max-900 row p-md-2 p-lg-0 p-0 mystyle">
    <form class="message-form" (ngSubmit)="sendMessage($event)">
      <div class="ai-roleplay-conversation">
        <div class="roleplay-info">
          <div>
            <h1>{{ roleplay?.name }}</h1>
          </div>
          <div>
            <div>{{ roleplay?.description }}</div>
          </div>
        </div>

        <div class="content">
          <div class="message-container" #messageContainer>
            <div
              class="message"
              *ngFor="let conversation of conversations"
              [class.my-message]="conversation.ai === false"
            >
              <div class="message-content-wrapper">
                <div class="user">
                  <span class="username">
                    {{ conversation.ai === false ? "You" : "AI" }}
                  </span>
                  <span class="timestamp">
                    {{ conversation?.createdAt | date : "EEEE h:mm a" }}
                  </span>
                </div>

                <div class="message-content-container">
                  <div class="message-content">
                    {{ conversation?.message }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="self-bottom d-flex">
          <input
            type="text"
            class="form-control"
            placeholder="{{ 'text' | transloco }}"
            autocomplete="off"
            [(ngModel)]="newMessage"
            name="newMessage"
            required
          />
          <button type="submit" class="btn btn-orange shadow-sm">
            {{ "send" | transloco }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
