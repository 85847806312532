<div class="row page-header d-flex justify-content-center me-sm-1 ms-sm-1">
  <div class="col-12 col-lg-11 col-xl-10 max-950 row p-md-2 p-lg-0 p-0">
    <div class="col-6 mt-sm-4 mb-sm-3 p-sm-0 ps-2">
      <h2 class="page-title" i18n="Label users">
        {{ "news" | transloco }}
      </h2>
    </div>
    <div class="col-6 mt-sm-4 mb-sm-3 p-0 d-flex justify-content-end">
      <p-button
        label="{{ 'add' | transloco }}"
        icon="pi pi-plus"
        [routerLink]="['/posts/admin/new']"
      />
    </div>

    <p-table #dt1 [value]="allNews" [tableStyle]="{ 'min-width': '50rem' }">
      <ng-template pTemplate="header">
        <tr>
          <th>
            <span class="p-input-icon-left ml-auto">
              <i class="pi pi-search"></i>
              <input
                pInputText
                type="text"
                (input)="onGlobalFilter(dt1, $event)"
                placeholder="{{ 'search' | transloco }}"
              />
            </span>
          </th>
          <th></th>
          <th></th>
          <th>
            <p-columnFilter
              field="userGroupNames"
              matchMode="contains"
              [showMenu]="false"
            >
              <ng-template
                pTemplate="filter"
                let-value
                let-filter="filterCallback"
              >
                <p-dropdown
                  [ngModel]="value"
                  [options]="userGroupsForSort"
                  (onChange)="filter($event.value)"
                  placeholder="{{ 'select' | transloco }}"
                  [showClear]="true"
                  [filter]="true"
                >
                  <ng-template let-option pTemplate="item">
                    <p-tag [value]="option.value" severity="info"></p-tag>
                  </ng-template>
                </p-dropdown>
              </ng-template>
            </p-columnFilter>
          </th>
        </tr>
        <tr>
          <th pSortableColumn="title">
            {{ "title" | transloco }} <p-sortIcon field="title"></p-sortIcon>
          </th>
          <th pSortableColumn="createdAt">
            {{ "createdAt" | transloco }}
            <p-sortIcon field="createdAt"></p-sortIcon>
          </th>
          <th>{{ "description" | transloco }}</th>
          <th>{{ "userGroups" | transloco }}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-news>
        <tr>
          <td>{{ news.title }}</td>
          <td>{{ news.createdAt | date : "dd-MM-yyyy hh:mm" }}</td>
          <td>
            <span
              [innerHTML]="truncateHtml(news.description)"
              class="text-break splice-text"
            ></span>
          </td>
          <td>
            <span pTooltip="{{ news.userGroupNames }}" tooltipPosition="top">{{
              getFieldValue(news.userGroupNames)
            }}</span>
          </td>
          <td>
            <div style="display: flex; justify-content: end">
              <div>
                <a
                  class="btn btn-md btn-link w-100"
                  [routerLink]="['/posts/admin/', news.id]"
                >
                  <img src="assets/img/edit.svg" />
                </a>
              </div>
              <div>
                <button
                  type="button"
                  class="btn btn-md btn-link w-100 text-danger"
                  data-bs-target="#deleteusermodal"
                  (click)="deleteConfirmation = news"
                  title="{{ 'delete' | transloco }}"
                  i18n-title="Delete button"
                >
                  <img src="assets/img/delete.svg" />
                </button>
              </div>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>

    <app-spinner *ngIf="repo.isLoading$ | async"></app-spinner>

    <app-confirm-delete
      id="deleteusermodal"
      *ngIf="deleteConfirmation"
      [deleteCandidate]="deleteConfirmation.title"
      (confirm)="
        handleDeleteClick(deleteConfirmation); deleteConfirmation = null
      "
      (cancel)="deleteConfirmation = null"
    ></app-confirm-delete>
  </div>
</div>
