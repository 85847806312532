import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { getRegistry } from '@ngneat/elf';
import { BehaviorSubject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';

import {
  AuthInfo,
  AuthProps,
  AuthRepository,
  PasswordChangeRequest,
  trackAuthRequestsStatus,
} from './auth.repository';
import { GeneralAppDataDto } from 'app/api/models/auth/sideBarInfoDto';
import { GeneralDataRepository } from 'app/helpers/repository/general-data.repository';
const API = '/api/auth';

const USER_INDEPENDENT_STORES = ['ui', 'requests'];

@Injectable({ providedIn: 'root' })
export class AuthService {
  private userPassword$ = new BehaviorSubject<string>('');
  lastUserPassword$ = this.userPassword$.asObservable();

  constructor(
    private http: HttpClient,
    private repo: AuthRepository,
    public translocoService: TranslocoService,
    private router: Router
  ) {}

  login(
    email: string,
    password: string,
    tenantId = null,
    isRemembered = false
  ) {
    return this.http.post<AuthProps>(API, {
      email: email,
      password: password,
      rememberme: isRemembered,
    });
  }

  logout() {
    localStorage.setItem('activeTenantId', '');
    localStorage.setItem('curUserLang', 'en');
    this.translocoService.setActiveLang(
      localStorage.getItem('curUserLang') ?? 'en'
    );
    localStorage.setItem('specialToken', '');

    return this.http.delete<AuthProps>(API).subscribe({
      next: (response) => {
        if (response && response.user) {
          this.repo.setUser(response.user);
          this.translocoService.setActiveLang(response.user.language ?? 'en');
          localStorage.removeItem('activeTenantId');
          this.repo.setToken(response.token);
          this.router.navigate(['/home']).then(() => {
            window.location.reload();
          });
        } else {
          this.router.navigate(['/signin']);
        }
      },
      error: (error) => {
        this.router.navigate(['/signin']);
      },
      complete: () => {},
    });
  }

  impersonate(userId: string, tenantId: string | null) {
    return this.http.post<AuthProps>(`${API}/impersonate`, {
      userId: userId,
      tenantId,
    });
  }

  setPassword(password: string) {
    this.userPassword$.next(password);
  }

  setActiveTenant(refreshToken: string, id: string | null = null) {
    let token;
    if (refreshToken && refreshToken.length >= 1) {
      token = refreshToken;
    } else {
      console.log('token not exist');
      token = 'empty';
    }
    return this.http.post<AuthProps>(`${API}/setactivetenant`, {
      token: token,
      id: id,
    });
  }
  getUserLang() {
    return this.http.get<AuthInfo>(API + `/getUserLang`);
  }

  getUserInfo() {
    return this.http.get<AuthInfo>(API + '/getuserinfo');
  }

  getGeneralAppDataForUser(id?: string) {
    let url = `${API}/sidebar`;
    if (id) {
      url += `?id=${id}`;
    }
    return this.http.get<GeneralAppDataDto>(url);
  }

  loginOffice365Auth(email: string) {
    return this.http
      .post<AuthProps>(`${API}/loginOffice365Auth/`, { email })
      .pipe(
        tap((resp) => {
          this.repo.setToken(resp?.token);
        }),
        tap((resp) =>
          localStorage.setItem('curUserLang', resp?.user?.language ?? 'en')
        ),
        tap((resp) =>
          this.translocoService.setActiveLang(resp?.user?.language ?? 'en')
        ),
        trackAuthRequestsStatus(this.repo.name)
      );
  }

  forgot(email: string) {
    return this.http.post<AuthProps>(`${API}/forgot`, { email });
  }

  reset(password: string, id: string, token: string) {
    return this.http.post<AuthProps>(`${API}/reset`, { password, id, token });
  }

  changePassword(dto: PasswordChangeRequest) {
    return this.http.post<void>(`${API}/password`, dto);
  }

  unimpersonate() {
    return this.http.delete<AuthProps>(`${API}/impersonate`).pipe(
      tap(() => this.clearStores()),
      tap((resp) => {
        this.repo.setToken(resp?.token), this.repo.setUserGroups(resp);
      }),
      trackAuthRequestsStatus(this.repo.name)
    );
  }

  refresh(token?: string, tenantId?: string) {
    let tokenOut;
    if (token && token.length >= 1) {
      tokenOut = token;
    } else {
      console.log('token not exist');
      tokenOut = 'empty';
    }
    if (!tenantId) {
      tenantId = 'emptyTenantId';
    }
    return this.http
      .post<AuthProps>(
        `${API}/refresh?token=${tokenOut}&tenantId=${tenantId}`,
        { token, tenantId }
      )
      .pipe(
        tap((resp) => {
          var activeTenant = resp.user?.tenants?.find((x) => x.id == tenantId);
          this.repo.setTenantProps(activeTenant?.isPartnerTenant ?? false);

          this.repo.setToken(resp?.token);
        }),
        trackAuthRequestsStatus(this.repo.name)
      )
      .pipe(
        tap((resp) => {
          localStorage.setItem('curUserLang', resp.user?.language ?? 'en');
          this.translocoService.setActiveLang(resp.user?.language ?? 'en');
        })
      );
  }

  autoRefresh() {
    let token = localStorage.getItem('specialToken');
    let tenantId = localStorage.getItem('activeTenantId');

    return this.repo.isExpiresSoon$.pipe(
      switchMap(() => this.refresh(token!, tenantId!))
    );
  }

  private clearStores(): void {
    getRegistry().forEach((store, key) => {
      if (!USER_INDEPENDENT_STORES.includes(key)) {
        store.reset();
      }
    });
  }
}
