<h2 class="mb-4">
  {{
    id === "new" ? ("createRoleplay" | transloco) : ("editRoleplay" | transloco)
  }}
</h2>

<div class="row mb-4" *ngIf="roleplay || id === 'new'">
  <app-ai-roleplay-form
    [editedRoleplay]="roleplay"
    (roleplaySubmit)="onRoleplaySubmit($event)"
  >
  </app-ai-roleplay-form>

  <app-error-alert
    title="{{ 'RoleplayError' | transloco }}"
    [errors]="submitErrors"
  ></app-error-alert>
</div>
