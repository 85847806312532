<div class="d-flex" #activeOptionsConatiner>
  <div
    class="option-tile"
    *ngIf="forFilter && activeOptions?.length"
    (click)="checkEvery(false); saveOptions()"
  >
    <img
      src="assets/icons/add-plus.svg"
      style="filter: brightness(0); transform: rotate(45deg)"
    />
  </div>
  <div
    *ngFor="let item of activeOptions | slice : 0 : maxTilesAllowed!"
    class="option-tile"
    data-bs-toggle="modal"
    [attr.data-bs-target]="'#' + datalistId"
    (click)="setOptions()"
  >
    <div *ngIf="forUsers">
      <app-entity-avatar-init
        class="mt-1 me-2"
        [size]="20"
        [img]="item.option.imagePath"
        [name]="item.option.label"
      ></app-entity-avatar-init>
    </div>

    <span>{{
      withTranslations ? (item.option.label | transloco) : item.option.label
    }}</span>
  </div>
  <div
    class="option-tile"
    data-bs-toggle="modal"
    [attr.data-bs-target]="'#' + datalistId"
    triggers="hover"
    (click)="setOptions()"
    popover="{{
      activeOptions && activeOptions.length > maxTilesAllowed!
        ? activeOptionsString
        : null
    }}"
  >
    <img src="assets/img/plus_green.svg" alt="Add" class="plus_image me-1" />
    <span>{{
      activeOptions && activeOptions.length > maxTilesAllowed!
        ? activeOptions.length - maxTilesAllowed + " " + ("more" | transloco)
        : ("add" | transloco)
    }}</span>
  </div>
</div>

<div
  class="modal fade"
  style="background-color: rgba(0, 0, 0, 0.5); backdrop-filter: blur(5px)"
  id="{{ datalistId }}"
  data-bs-backdrop="false"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <div class="col-12 d-flex">
          <h5 class="modal-title col-11" id="staticBackdropLabel">
            {{ "select" | transloco }} {{ "options" | transloco }}
          </h5>
          <button
            type="button"
            class="btn-close col-1"
            data-bs-dismiss="modal"
            aria-label="Close"
            (click)="resetSearch()"
          ></button>
        </div>
        <app-search-input
          class="w-100"
          [items]="modalOptions"
          [searchProperties]="['option.label']"
          (foundItems)="filteredModalOptions = $event"
        />
      </div>
      <div class="modal-body p-0">
        <p
          *ngIf="!filteredModalOptions?.length; else checkAllContainer"
          class="text-muted m-2 ms-3"
        >
          {{ "noItemsFound" | transloco }}
        </p>
        <ng-template #checkAllContainer>
          <div
            class="option form-sheck p-2 ps-3 col-12"
            *ngIf="filteredModalOptions?.length == options?.length"
          >
            <input
              role="button"
              class="form-check-input me-2"
              type="checkbox"
              #checkAll
              id="checkAll{{ datalistId }}"
              (change)="checkEvery(checkAll.checked)"
              [(ngModel)]="allChecked"
            />
            <label
              class="form-check-label"
              role="button"
              for="checkAll{{ datalistId }}"
            >
              {{ "checkAll" | transloco }} {{ "options" | transloco }}</label
            >
          </div>
        </ng-template>
        <div class="d-flex" style="flex-wrap: wrap">
          <div
            *ngFor="let item of filteredModalOptions"
            class="option form-sheck p-2 ps-3 {{
              filteredModalOptions!.length > 15 ? 'col-12 col-sm-6' : 'col-12'
            }}"
            [class.even-options]="filteredModalOptions!.length % 2 === 0"
          >
            <input
              role="button"
              class="form-check-input mt-0 me-2"
              style="aspect-ratio: 1"
              type="checkbox"
              #itemCheckbox
              (change)="check(item, itemCheckbox.checked)"
              [ngModel]="item.checked"
              id="{{ item.option.value }}"
            />
            <label
              class="form-check-label w-75"
              role="button"
              for="{{ item.option.value }}"
            >
              <app-entity-avatar-init
                *ngIf="item.option.imagePath || forUsers"
                class="mt-1 me-2"
                [size]="30"
                [img]="item.option.imagePath"
                [name]="item.option.label"
              ></app-entity-avatar-init>
              {{ item.option.label }}</label
            >
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          data-bs-dismiss="modal"
          (click)="resetSearch()"
        >
          Close
        </button>
        <button
          type="button"
          class="btn btn-orange"
          data-bs-dismiss="modal"
          (click)="saveOptions()"
        >
          {{ "confirm" | transloco }}
        </button>
      </div>
    </div>
  </div>
</div>
