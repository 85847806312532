import { Component, HostListener, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NewsAdminPageListDto } from 'app/api/models/news/newsAdminPageListDto';

import { AuthRepository } from 'app/state/auth.repository';
import { Post, PostsRepository } from 'app/state/posts.repository';
import { PostsService } from 'app/state/posts.service';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-news-admin',
  templateUrl: './news-admin.component.html',
  styleUrls: ['./news-admin.component.scss'],
})
export class NewsAdminComponent implements OnInit {
  allNews: NewsAdminPageListDto[] = [];
  innerWidth = 0;
  userGroupsForSort: any[] = [];
  deleteConfirmation: Post | null = null;
  constructor(
    public repo: PostsRepository,
    public service: PostsService,
    public auth: AuthRepository,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.refreshData();
    this.innerWidth = window.innerWidth;
  }
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  refreshData() {
    this.service.getNewsAdminPage().subscribe((data) => {
      this.allNews = data;
      this.updateUserGroupsForFilter();
    });
  }

  handleDeleteClick(post: Post) {
    this.service.deleteNews(post.id).subscribe((x) => {
      this.refreshData();
    });
  }

  getFieldValue(array: any[]) {
    if (array.length > 1) {
      return array[0] + '...';
    }
    return array[0];
  }

  onGlobalFilter(table: Table, event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    table.filterGlobal(inputElement.value, 'contains');
  }

  updateUserGroupsForFilter() {
    const groupsSet = new Set<string>();
    if (!this.allNews) return;
    this.allNews.forEach((news) => {
      if (news.userGroupNames && Array.isArray(news.userGroupNames)) {
        news.userGroupNames.forEach((group) => {
          groupsSet.add(group);
        });
      }
    });

    this.userGroupsForSort = Array.from(groupsSet).map((group) => ({
      label: group,
      value: group,
    }));
  }

  truncateHtml(html: string): SafeHtml {
    let div = document.createElement('div');
    div.innerHTML = html;

    if (div.textContent && div.textContent.length > 35) {
      div.textContent = div.textContent.slice(0, 35) + '...';
    }

    return this.sanitizer.bypassSecurityTrustHtml(div.innerHTML);
  }
}
