import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { BlobStorageService } from 'app/shared/azure-blob/blob-storage.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MediaLibraryFileDto } from 'app/api/models/mediaLibrary/mediaLibraryFileDto';
import { MediaLibraryService } from 'app/api/services/mediaLibrary.service';

@Component({
  selector: 'app-media-library-multiple-files-selector',
  templateUrl: './media-library-multiple-files-selector.component.html',
  styleUrls: ['./media-library-multiple-files-selector.component.scss'],
})
export class MedialLibraryMultipleFilesSelectorComponent implements OnInit {
  constructor(
    public blobService: BlobStorageService,
    public mediaLibraryService: MediaLibraryService
  ) {}
  @Output() onFileSelected = new EventEmitter<MediaLibraryFileDto>();
  @Output() onFileRemoved = new EventEmitter<MediaLibraryFileDto>();
  @Input() mediaLibraryFiles?: MediaLibraryFileDto[] = []; //files to display must be an array (Pavlo)
  @Input() allowMultipleSelection: boolean = false;
  @Input() allowImagesSelection: boolean = false;
  @Input() allowDocumentsSelection: boolean = false;
  @Input() allowVideosSelection: boolean = false;
  @Input() allowSoundsSelection: boolean = false;
  @Input() selectionDisabled: boolean = false;
  @Input() uploadOnlyPrivateFilesModeActive: boolean = false;

  ngOnInit(): void {}

  selectAttachedMediaLibraryFiles() {
    this.mediaLibraryService
      .selectFileFromMediaLibraryInModal(
        this.allowImagesSelection,
        this.allowDocumentsSelection,
        this.allowVideosSelection,
        this.allowSoundsSelection,
        this.uploadOnlyPrivateFilesModeActive
      )
      .subscribe((data) => {
        if (data) {
          this.onFileSelected.emit(data);
        }
      });
  }

  onFileRemove(mediaLibraryFile: MediaLibraryFileDto) {
    this.onFileRemoved.emit(mediaLibraryFile);
  }
}
