import { Component, HostListener, OnInit } from '@angular/core';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthRepository, UserRoles } from 'app/state/auth.repository';
import { Post, PostsRepository } from 'app/state/posts.repository';
import { PostsService } from 'app/state/posts.service';
import { postSortOptions } from 'app/state/posts.repository';
import { BlobStorageService } from 'app/shared/azure-blob/blob-storage.service';
import { NewsUserPageListDto } from 'app/api/models/news/newsUserPageListDto';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
})
export class NewsComponent implements OnInit {
  DEFAULT_POST_IMAGE = 'assets/img/preview.jpg';
  innerWidth = 0;
  active: string = 'All';
  unreadCount: number = 0;

  visibleNews: NewsUserPageListDto[] = [];
  allNews: NewsUserPageListDto[] = [];
  readNews: NewsUserPageListDto[] = [];
  unreadNews: NewsUserPageListDto[] = [];

  constructor(
    public repo: PostsRepository,
    public service: PostsService,
    public auth: AuthRepository,
    public blobService: BlobStorageService
  ) {}

  news: Post[] = [];
  ngOnInit(): void {
    this.service.getNewsUserPage().subscribe((data) => {
      this.visibleNews = data;
      this.allNews = data;
      this.feelNews();
    });
    this.innerWidth = window.innerWidth;
  }
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  transform(value?: string): any {
    var string = value
      ? value.replace(/<.*?>/g, '').replace(/&nbsp;/g, '')
      : '';
    return string.length > 80 ? string.slice(0, 80) + '...' : string;
  }

  changeActive(option: string) {
    this.active = option;
    if (option == 'Unread') {
      this.visibleNews = this.unreadNews;
    }
    if (option == 'All') {
      this.visibleNews = this.allNews;
    }
    if (option == 'Read') {
      this.visibleNews = this.readNews;
    }
  }

  feelNews() {
    this.readNews = this.allNews.filter((news) => news.isRead === true);
    this.unreadNews = this.allNews.filter((news) => news.isRead === false);

    this.unreadCount = this.unreadNews.length;
  }
}
