import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FileSetttings } from 'app/shared/data.service';
import {
  Tenant,
  TenantHomeUpdateModel,
  TenantsRepository,
} from 'app/state/tenants.repository';
import { TenantsService } from 'app/state/tenants.service';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-tenant-edithtml-page',
  templateUrl: './tenant-edithtml-page.component.html',
  styleUrls: ['./tenant-edithtml-page.component.scss'],
})
export class TenantEdithtmlPageComponent {
  tenant: Tenant | null = null;
  id: string | null = null;
  submitErrors: string[] | null = null;

  editTenantModel!: TenantHomeUpdateModel;

  constructor(
    private service: TenantsService,
    private route: ActivatedRoute,
    public repo: TenantsRepository,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe({
      next: (value) => {
        this.id = value.get('id')!;
        if (this.id) {
          this.service.loadOne(this.id).subscribe((x) => {
            this.tenant = x;
            const model: TenantHomeUpdateModel = {
              logo: x?.homePageMediaFile ?? undefined,
              pageHtml: x?.pageHtml ?? '',
              videoUrl: x?.videoUrl ?? '',
              pageHtml2: x?.pageHtml2 ?? '',
              headline: x?.headline ?? '',
            };
            this.editTenantModel = model;
          });
        }
      },
    });
    this.setUpEditModel();
  }

  setUpEditModel() {
    const model: TenantHomeUpdateModel = {
      logo: this.tenant?.homePageMediaFile ?? undefined,
      pageHtml: this.tenant?.pageHtml,
      videoUrl: this.tenant?.videoUrl,
      pageHtml2: this.tenant?.pageHtml2,
      headline: this.tenant?.pageHtml2,
    };
    this.editTenantModel = model;
  }

  update(tenantModel: TenantHomeUpdateModel) {
    let updateResult: Observable<Tenant> | undefined;
    if (this.id) {
      updateResult = this.service.updateTenantContent(this.id, tenantModel);
    }
    if (updateResult) {
      updateResult.subscribe({
        complete: () => {
          this.router.navigate(['tenants']);
        },
        error: (data) => (this.submitErrors = data),
      });
    }
  }
}
