import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BlobStorageService } from 'app/shared/azure-blob/blob-storage.service';
import { MediaLibraryFileDto } from 'app/api/models/mediaLibrary/mediaLibraryFileDto';
import { MediaLibraryService } from 'app/api/services/mediaLibrary.service';

@Component({
  selector: 'app-media-library-file-icon',
  templateUrl: './mediaLibrary-file-icon.component.html',
  styleUrls: ['./mediaLibrary-file-icon.component.scss'],
})
export class MedialLibraryFileIconComponent implements OnInit {
  @Input() mediaLibraryFileDto?: MediaLibraryFileDto;
  @Input() maxImageWidth: number = 150;
  @Input() allowFileRemoving: boolean = false;
  @Output() removeFile = new EventEmitter<MediaLibraryFileDto>();

  constructor(
    public blobService: BlobStorageService,
    public mediaLibraryService: MediaLibraryService
  ) {}

  ngOnInit(): void {}

  onDeleteFile() {
    this.removeFile.emit(this.mediaLibraryFileDto);
  }
}
