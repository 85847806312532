import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-entity-avatar-init',
  templateUrl: './entity-avatar-init.component.html',
  styleUrls: ['./entity-avatar-init.component.scss'],
})
export class EntityAvatarInitComponent implements OnInit {
  @Input() size: number = 40;
  @Input() img: string | undefined;
  @Input() set name(value: string | undefined) {
    if (!value) {
      this.initialsName = '';
    } else {
      this.initialsName = value.replace(/[^A-Z]/g, '');
    }
  }
  @Input() set surname(value: string | undefined) {
    if (!value) {
      this.initialSurName = '';
    } else {
      this.initialSurName = value.replace(/[^A-Z]/g, '');
    }
  }

  initialsName: string | null = null;
  initialSurName: string | null = null;

  constructor() {}

  ngOnInit(): void {}
}
