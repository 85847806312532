import { Component, OnInit } from '@angular/core';
import { AIRoleplayService } from 'app/state/ai-roleplay.service';
import { AIRoleplayDto } from 'app/api/models/ai-roleplay/ai-roleplayDto';
import { AuthRepository } from 'app/state/auth.repository';

@Component({
  selector: 'app-ai-roleplay-page',
  templateUrl: './ai-roleplay-page.component.html',
  styleUrls: ['./ai-roleplay-page.component.scss'],
})
export class AIRoleplayPageComponent implements OnInit {
  isLoading: boolean = true;
  filteredAIRoleplay: AIRoleplayDto[] | null = null;
  tenantId: string | null = null;
  airoleplays: AIRoleplayDto[] = [];
  innerWidth = 0;
  constructor(
    public auth: AuthRepository,
    private service: AIRoleplayService
  ) {}
  // ngOnInit(): void {
  //   // this.refreshData();
  //   this.innerWidth = window.innerWidth;
  // }
  ngOnInit(): void {
    this.loadRoleplays();
  }

  loadRoleplays() {
    this.service.getAllRoleplays().subscribe((data: AIRoleplayDto[]) => {
      this.airoleplays = data;
      this.filteredAIRoleplay = data;
      this.isLoading = false;
    });
  }
}
