<div>
  <div class="date mb-2">
    {{ news.createdAt | date : "d MMM y" }}
  </div>
  <div class="mainTitle text-breaktext-break pb-4">
    {{ news.title }}
  </div>

  <div>
    <div
      *ngIf="
        news.videoSoundMediaLibraryFile || news.videoSoundUrl;
        else elseBlock
      "
    >
      <video
        *ngIf="news.videoSoundMediaLibraryFile; else elseIframeBlock"
        width="100%"
        style="max-height: 50vh"
        muted
        preload="metadata"
        controls
      >
        <source
          [src]="news.videoSoundMediaLibraryFile.sanitazedFileUrl"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <ng-template #elseIframeBlock>
        <div *ngIf="sanitazedFileUrl" style="width: 100%; height: 50vh">
          <iframe
            style="width: 100%; height: 100%"
            [src]="sanitazedFileUrl"
            frameborder="0"
          ></iframe>
        </div>
      </ng-template>
    </div>
    <ng-template #elseBlock>
      <div *ngIf="news.previewMediaLibraryImage">
        <app-media-library-file-icon
          class="ms-3"
          [mediaLibraryFileDto]="news.previewMediaLibraryImage"
          [maxImageWidth]="400"
        ></app-media-library-file-icon>
      </div>
    </ng-template>

    <div style="margin-top: 50px">
      <p class="text-break content" [innerHTML]="news.description"></p>
    </div>
  </div>
</div>
